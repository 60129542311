export const accessNames = {
  fullAccess: "fullAccess",
  user: "user",
  application: "appeal",
  notification: "notification",
  employee: "employee",
  role: "role",
  log: "log",
  banner: "banner",
  loyalty: "loyalty",
  settings: 'settings'
};