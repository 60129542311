import { Form, Input, InputNumber, Select, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import type { UploadFile } from "antd/es/upload/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { uploadLoyalityImage } from "../../../../../../api/requests/loyalty";
import { ClubIds } from "../../../../../../common/constants/constants";
import { formsConstantsValidation } from "../../../../../../common/constants/formsConstantsValidation";
import { validationHyperLink } from "../../../../../../common/helpers/commonValidators/validationHyperLink";
import { IThirdStep } from "../../../../../../common/interfaces/ILoyaltyForm";
import { StateType } from "../../../../../../core/redux/store";
import { LoyaltyTitleLayout } from "../../../../../../ui/LoyaltyTitleLayout";
import { FileUpload } from "../../../../../../ui/formItemComponents/FileUpload";
import { Wysiwyg } from "../../../../../../ui/wysiwig/Wysiwig";

const { Option } = Select;

const entity = formsConstantsValidation.entity.loyalty;

export const ThirdStepScreen = ({ ...props }: IThirdStep) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<UploadFile<any>[]>();
  const loyaltyTypes = useSelector((state: StateType) => state.loyalty.type);

  const typeOptions = useMemo(
    () =>
      loyaltyTypes.map((type: string) => (
        <Option key={type} value={type}>
          {t(`loyaltyForm.thirdStep.awards.${type}`)}
        </Option>
      )),
    [loyaltyTypes]
  );

  const handleRemoveFile = async (info: UploadFile) => {
    setUploadedFile([]);

    return new Promise<boolean>((resolve) => {
      props.setFreebetFile(undefined);
      resolve(true);
    });
  };

  useEffect(() => {
    props.form.resetFields(['typeAwardId']);
    props.setTypeAwardId(null);
  }, [props.condition]);

  useEffect(() => {
    if (props.freebetFile) {
      setUploadedFile([
        {
          uid: "-1",
          name: props.freebetFile?.slice(props.freebetFile.lastIndexOf("/") + 1),
          status: "done",
          url: props.freebetFile
        }
      ]);
    } else {
      setUploadedFile(undefined);
    }
  }, [props.freebetFile]);

  useEffect(() => {
    if (props.typeAwardId === "freebet") {
      props.form.resetFields(["voucherQuantity", "couponQuantity", "externalLink", "buttonText"]);
      props.setFreebet(true);
      props.setFreeBetByPhone(false);
      props.setVoucher(false);
      props.setCoupon(false);
      props.setLink(false);
    } else if (props.typeAwardId === "FreeBetByPhone") {
      props.form.resetFields(["voucherQuantity", "couponQuantity", "externalLink", "buttonText"]);
      props.setFreeBetByPhone(true);
      props.setFreebet(false);
      props.setVoucher(false);
      props.setCoupon(false);
      props.setLink(false);
    } else if (props.typeAwardId === "voucherToTheBox") {
      props.form.resetFields(["uploadFileFreebet", "couponQuantity", "externalLink", "buttonText"]);
      props.setVoucher(true);
      props.setFreeBetByPhone(false);
      props.setFreebet(false);
      props.setCoupon(false);
      props.setLink(false);
    } else if (props.typeAwardId === "couponForMerchandise") {
      props.form.resetFields(["uploadFileFreebet", "voucherQuantity", "externalLink", "buttonText"]);
      props.setCoupon(true);
      props.setFreeBetByPhone(false);
      props.setVoucher(false);
      props.setFreebet(false);
      props.setLink(false);
    } else if (props.typeAwardId === "FreeBetByPhone") {
      props.form.resetFields(["uploadFileFreebet", "voucherQuantity", "externalLink", "buttonText"]);
      props.setCoupon(true);
      props.setFreeBetByPhone(false);
      props.setVoucher(false);
      props.setFreebet(false);
      props.setLink(false);
    } else {
      props.form.resetFields(["uploadFileFreebet", "voucherQuantity", "couponQuantity"]);
      props.setLink(true);
      props.setFreeBetByPhone(false);
      props.setCoupon(false);
      props.setVoucher(false);
      props.setFreebet(false);
    }
  }, [props.typeAwardId]);

  const handleSelectChange = (value: string) => {
    props.setTypeAwardId(value);
    props.setStepCount(1);
  };

  const handleVoucherChange = (value: number) => {
    props.setVoucherValue(value);
  };

  const handleCouponChange = (value: number) => {
    props.setCouponValue(value);
  };

  useEffect(() => {
    if (!props.coupon) {
      props.setCouponValue(0);
    }
    if (!props.voucher) {
      props.setVoucherValue(0);
    }
    if (!props.freebet) {
      props.setFreebetFile("");
    }
    if (!props.link) {
      props.setExternalLink("");
      props.setButtonText("");
    }
  }, [props.coupon, props.voucher, props.link, props.freebet]);

  return (
    <MainBlock>
      <LoyaltyTitleLayout title={t("common.type")} third>
        <Form.Item rules={[{ required: true, message: t("validations.required") }]} name={"typeAwardId"}>
          <Select
            disabled={props.edit}
            onChange={handleSelectChange}
            placeholder={t("common.selectPlaceholder")}
          >
            {typeOptions.map(option => (
              <>
              <Select.Option key={option.props.value} value={option.props.value}
              disabled={
                //FreeBetByPhone(фрибет-награда) для Краснодара и Трактора при условии доступа что из файла
                option.props.value === "FreeBetByPhone" && (props.condition !== "FromFile"
                ||( props.form.getFieldValue("clubId") !== ClubIds.Traktor
                && props.form.getFieldValue("clubId") !== ClubIds.Krasnodar))
                }>
                {option.props.children}
              </Select.Option>
              </>
            ))}
          </Select>
        </Form.Item>
      </LoyaltyTitleLayout>
      {props.typeAwardId === "couponForMerchandise" ? (
        <LoyaltyTitleLayout title={t("loyaltyForm.thirdStep.count")} description={t("loyaltyForm.thirdStep.countDesc")} third>
          <Form.Item name={"couponQuantity"} rules={[{ required: true, message: t("validations.required") }]}>
            <InputNumber
              disabled={props.edit ? true : false}
              style={{ width: "100%" }}
              placeholder="0"
              formatter={(value) => `${value}`.replace(/[^0-9]/g, "")}
              onChange={handleCouponChange}
              min={formsConstantsValidation.default.minCount}
              max={formsConstantsValidation.default.maxCount}
            />
          </Form.Item>
        </LoyaltyTitleLayout>
      ) : props.typeAwardId === "freebet" ? (
        <LoyaltyTitleLayout
          title={t("loyaltyForm.thirdStep.freebet.title")}
          description={t("loyaltyForm.thirdStep.freebet.description1")}
          description2={t("loyaltyForm.thirdStep.freebet.description2")}
          third
        >
          <FileUpload
            label=""
            setValue={props.setFreebetFile}
            disabled={props.edit ? true : false}
            action={`${process.env.REACT_APP_ADMIN}/Loyalty/AddFile?fileContentType=Awards`}
            form={props.form}
            maxCount={1}
            types="CSV"
            name={"uploadFileFreebet"}
            mimeTypes={["text/csv"]}
            fileList={uploadedFile}
            onRemove={handleRemoveFile}
          />
        </LoyaltyTitleLayout>
      ) : props.typeAwardId === "voucherToTheBox" ? (
        <LoyaltyTitleLayout
          title={t("loyaltyForm.thirdStep.voucher.title")}
          description={t("loyaltyForm.thirdStep.voucher.description1")}
          description2={t("loyaltyForm.thirdStep.voucher.description2")}
          third
        >
          <Form.Item name={"voucherQuantity"} rules={[{ required: true, message: t("validations.required") }]}>
            <InputNumber
              disabled={props.edit ? true : false}
              style={{ width: "100%" }}
              placeholder="0"
              formatter={(value) => `${value}`.replace(/[^0-9]/g, "")}
              onChange={handleVoucherChange}
              min={formsConstantsValidation.default.minCount}
              max={formsConstantsValidation.entity.loyalty.maxCount}
            />
          </Form.Item>
        </LoyaltyTitleLayout>
      ) : props.typeAwardId === "externalReference" ? (
        <div>
          <LoyaltyTitleLayout
            title={t("common.link")}
            description={t("loyaltyForm.thirdStep.link.linkDescription")}
            description2={t("loyaltyForm.thirdStep.link.linkDescription2")}
            third
          >
            <Form.Item
              name={"externalLink"}
              rules={[
                {
                  validator: validationHyperLink
                }
              ]}
            >
              <Input
                disabled={props.edit ? true : false}
                placeholder={t("loyaltyForm.thirdStep.link.linkPlaceholder")}
                size={"middle"}
                onChange={(e) => props.setExternalLink(e.target.value)}
              />
            </Form.Item>
          </LoyaltyTitleLayout>
          <div style={{ height: "20px" }} />
          <LoyaltyTitleLayout title={t("common.textButton")} description={t("loyaltyForm.thirdStep.link.buttonDescription")} third>
            <Form.Item
              name={"buttonText"}
              rules={[
                {
                  required: true,
                  message: t("validations.required")
                },
                {
                  max: entity.buttonText.max,
                  message: t("validations.maxLengthExceeded", { max: entity.buttonText.max })
                }
              ]}
            >
              <Input
                disabled={props.edit ? true : false}
                placeholder={t("loyaltyForm.thirdStep.link.buttonPlaceholder")}
                size={"middle"}
                onChange={(e) => props.setButtonText(e.target.value)}
              />
            </Form.Item>
          </LoyaltyTitleLayout>
        </div>
      ) : props.typeAwardId === "FreeBetByPhone"  ?
        <LoyaltyTitleLayout
          title={t("loyaltyForm.thirdStep.freebet.title")}
          description={t("loyaltyForm.thirdStep.freebet.description1")}
          description2={t("loyaltyForm.thirdStep.freebet.description2")}
          third
        >
          <FileUpload
            label=""
            setValue={props.setFreebetFile}
            disabled={props.edit ? true : false}
            action={`${process.env.REACT_APP_ADMIN}/Loyalty/AddFile?fileContentType=FreeBet`}
            form={props.form}
            maxCount={1}
            types="CSV"
            name={"FreeBetByPhone"}
            mimeTypes={["text/csv"]}
            fileList={uploadedFile}
            onRemove={handleRemoveFile}
          />
        </LoyaltyTitleLayout>
      : (
        ""
      )}
      <LoyaltyTitleLayout third title={t("loyalty.infoForWinners")} >
        <FormItem
          name={"WinnerText"}
        >
          <Wysiwyg  uploadRequest={uploadLoyalityImage}  />
        </FormItem>
      </LoyaltyTitleLayout>
    </MainBlock>
  );
};
const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25%;
  @media (max-width: 1000px) {
    grid-gap: 10%;
  }
`;

const SecondDesc = styled(Typography.Text)`
  display: block;
  margin-top: -20px;
  margin-bottom: 40px;
  width: 200px;
  font-size: 13px;
`;
